<template>
    <div class="questions-tabs">
        <b-container>
            <div @click="onClick" class="search">
                <div v-show="isPlaceholderOpened" class="search-placeholder">
                    <img src="@/assets/images/container/questions/search.png" />
                    输入关键字搜索问题与答案
                </div>

                <input
                    ref="input"
                    @blur="onBlur"
                    class="search-input"
                    v-model="search"
                />

                {{ search }}
            </div>

            <div class="view">
                <div ref="viewLeft" class="view-left">
                    <div
                        :class="[
                            'view-tab',
                            current === key && 'view-tab--current'
                        ]"
                        v-for="(list, key) in faqs"
                        :key="key"
                        @click="onTabClick(key)"
                    >
                        {{ key }}
                    </div>
                </div>
                <div
                    ref="viewRight"
                    :class="[
                        'view-right',
                        isViewRightRadius && 'view-right--radius'
                    ]"
                >
                    <div
                        class="view-item"
                        v-for="(item, idx) in questions"
                        :key="idx"
                        @click="onQuestionClick(item.id, idx)"
                    >
                        <div class="view-item__title">
                            <img
                                class="question"
                                src="@/assets/images/container/questions/question.png"
                            />
                            <span>{{ item.title }}</span>
                            <img
                                :class="[
                                    'arrow',
                                    item.isOpened && 'arrow--open'
                                ]"
                                src="@/assets/images/container/questions/arrow.png"
                            />
                        </div>

                        <div v-show="item.isOpened" class="detail">
                            <div class="detail-answer">答：</div>
                            <div class="detail-list">
                                <div
                                    v-for="(detail, idx) in details[item.id]"
                                    :key="idx"
                                    class="detail-item"
                                >
                                    <div class="detail-txt">
                                        {{ detail.text }}
                                    </div>
                                    <div class="detail-img">
                                        <img
                                            v-if="detail.img"
                                            :src="detail.img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            search: "",
            isPlaceholderOpened: true,
            faqs: {},
            allFaqs: [],
            current: "手表常见问题",
            details: {},
            questions: [],
            isViewRightRadius: false
        };
    },

    methods: {
        onClick() {
            this.isPlaceholderOpened = false;
            this.$refs.input.focus();
        },

        onBlur() {
            this.isPlaceholderOpened = !this.search;
        },

        onTabClick(key) {
            this.current = key;
            this.questions = this.faqs[key];

            this.$nextTick(() => {
                // 是否打开圆角
                this.isViewRightRadius =
                    this.$refs.viewRight.offsetHeight -
                        this.$refs.viewLeft.offsetHeight >
                    0;
            });
        },

        getFaqs() {
            this.$api
                .request({
                    url: "xingwawa/index.php",
                    method: "get",
                    params: {
                        m: "gm",
                        a: "get_faq_list_web"
                    }
                })
                .then(res => {
                    let faqs = {};
                    let allFaqs = [];
                    res.ret.forEach(item => {
                        let { id, type, title } = item;

                        type = decodeURIComponent(type);
                        title =  decodeURIComponent(title);

                        const _item = {
                            id,
                            type,
                            title,
                            isOpened: 0
                        };

                        faqs[type]
                            ? faqs[type].push(_item)
                            : (faqs[type] = [_item]);

                        allFaqs.push(_item);
                    });

                    this.faqs = faqs;
                    this.questions = this.faqs[this.current];
                    this.allFaqs = allFaqs;

                    console.log(faqs);
                });
        },

        onQuestionClick(id, idx) {
            let questions = this.questions;
            let { isOpened } = questions[idx];
            questions[idx].isOpened = !isOpened;

            if (isOpened) {
                return;
            }

            if (this.details[id]) {
                return;
            }

            this.$api
                .request({
                    url: "xingwawa/index.php",
                    method: "get",
                    params: {
                        m: "gm",
                        a: "get_faq_detail",
                        id
                    }
                })
                .then(res => {
                    let { detail } = res.ret;
                    detail = JSON.parse(decodeURIComponent(detail));

                    this.$set(this.details, id, detail);
                });
        }
    },

    watch: {
        search() {
            if(this.search.length > 0){
                let questions = this.allFaqs.filter(item => {
                    return item.title.indexOf(this.search) > -1;
                });

                this.questions = questions;
            }else{
                this.onTabClick(this.current);
            }
            
        }
    },

    mounted() {
        this.getFaqs();
    }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
    .questions-tabs {
        position: relative;
    }

    .search {
        color: #c4c4c4;
        font-size: calc(100vw / 1920 * 24);
        height: calc(100vw / 1920 * 64);
        background: #fff;
        margin-bottom: calc(100vw / 1920 * 30);
        text-align: center;
        border-radius: calc(100vw / 1920 * 10);
        overflow: hidden;
        position: relative;

        &-placeholder {
            position: absolute;
            left: 50%;
            top: 0;
            width: calc(100vw / 1920 * 960);
            height: calc(100vw / 1920 * 64);
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50%);

            img {
                width: calc(100vw / 1920 * 32);
                margin-right: calc(100vw / 1920 * 18);
            }
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
        }
    }

    .view {
        display: flex;

        &-left {
            background: #58cde4;
            width: calc(100vw / 1920 * 160);
            padding: calc(100vw / 1920 * 32) 0;
            font-size: calc(100vw / 1920 * 20);
            color: #fff;
            border-top-left-radius: calc(100vw / 1920 * 30);
            border-bottom-left-radius: calc(100vw / 1920 * 30);
            // max-height: calc(100vw / 1920 * 880);
        }

        &-right {
            width: calc(100vw / 1920 * 800);
            padding: calc(100vw / 1920 * 32) calc(100vw / 1920 * 20) 0;
            padding-right: calc(100vw / 1920 * 20);
            background: #fff;
            border-top-right-radius: calc(100vw / 1920 * 30);
            border-bottom-right-radius: calc(100vw / 1920 * 30);

            &--radius {
                border-bottom-left-radius: calc(100vw / 1920 * 30);
            }
        }

        &-tab {
            height: calc(100vw / 1920 * 64);
            line-height: calc(100vw / 1920 * 64);
            cursor: pointer;
            position: relative;

            + .view-tab {
                &::after {
                    content: " ";
                    height: calc(100vw / 1920 * 3);
                    width: calc(100vw / 1920 * 140);
                    position: absolute;
                    left: 50%;
                    top: 0;
                    background-image: url("../assets/images/container/questions/divider.png");
                    background-size: 100% 100%;
                    background-position: 0 0;
                    transform: translate(-50%, -100%);
                }
            }

            &--current {
                color: #383838;
                background: #fff;
                position: relative;
                z-index: 99;

                &::after {
                    background: none;
                }
            }
        }

        &-item {
            &__title {
                color: #495e8f;
                height: calc(100vw / 1920 * 64);
                line-height: calc(100vw / 1920 * 64);
                font-size: calc(100vw / 1920 * 24);
                display: flex;
                align-items: center;
                cursor: pointer;

                .question {
                    width: calc(100vw / 1920 * 29);
                    margin-left: calc(100vw / 1920 * 19);
                    margin-right: calc(100vw / 1920 * 18);
                }

                .arrow {
                    width: calc(100vw / 1920 * 24);
                    margin-left: calc(100vw / 1920 * 20);
                    transform: rotate(180deg);

                    &--open {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    .detail {
        color: #383838;
        font-size: calc(100vw / 1920 * 20);
        display: flex;
        padding-top: calc(100vw / 1920 * 20);
        padding-bottom: calc(100vw / 1920 * 35);
        border: 2px solid #eeeeee;
        border-radius: calc(100vw / 1920 * 10);
        margin-bottom: calc(100vw / 1920 * 18);

        &-answer {
            color: #444d84;
            width: calc(100vw / 1920 * 66);
            padding-left: calc(100vw / 1920 * 25);
        }

        &-list {
            text-align: left;
        }

        &-txt {
            margin-bottom: calc(100vw / 1920 * 20);
            padding-right: calc(100vw / 1920 * 25);
            text-align: justify;
            text-justify: inter-ideograph;
            max-width: calc(100vw / 1920 * 700);
        }

        &-img {
            margin-bottom: calc(100vw / 1920 * 20);

            img {
                width: calc(100vw / 1920 * 256);
            }
        }
    }
};

@media (max-width: 1024px) {
    .search {
        color: #c4c4c4;
        font-size: calc(100vw / 1080 * 36);
        height: calc(100vw / 1080 * 120);
        background: #fff;
        margin-bottom: calc(100vw / 1080 * 30);
        text-align: center;
        border-radius: calc(100vw / 1080 * 20);
        overflow: hidden;
        position: relative;

        &-placeholder {
            position: absolute;
            left: 50%;
            top: 0;
            width: calc(100vw - 30px);
            height: calc(100vw / 1080 * 120);
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(-50%);

            img {
                width: calc(100vw / 1080 * 57);
                margin-right: calc(100vw / 1080 * 45);
            }
        }

        input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
        }
    }
}
</style>
