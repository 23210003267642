<template>
	<div class="g-page">
		<Nav />

		<picture>
			<source
				media="(max-width: 1024px)"
				srcset="@/assets/images/mobile/container/questions/banner.png"
			/>
			<source
				media="(min-width: 1025px)"
				srcset="@/assets/images/container/questions/banner.png"
			/>
			<img
				class="banner"
				src="@/assets/images/container/questions/banner.png"
			/>
		</picture>

		<Questions />

		<Footer />

		<BackToTop />
	</div>
</template>

<script>
import Nav from "@/components/nav.vue";
import Questions from "@/components/questions.vue";
import Footer from "@/components/footer.vue";
import BackToTop from "@/components/back-to-top.vue";

export default {
	components: {
		Nav,
		Questions,
		Footer,
		BackToTop
	},

	data() {
		return {
			listIdxs: {
				questions: 0,
				feedback: 1
			}
		};
	},

	mounted() {}
};
</script>

<style lang="less">
.banner {
	width: 100%;
	margin-bottom: 70px;
}

@media (max-width: 1024px) {
	.banner {
		margin-bottom: calc(100vw / 1080 * 100);
	}
}
</style>
