<template>
    <div class="contact">
        <b-container>
            <div class="contact-msg">
                感谢您对兜兜龙的支持，如果您有任何意见或建议，请填写以下表格反馈给我们。我们会即时采纳，力求为您提供满意的服务。
            </div>

            <div class="contact-msg--mobile">
                感谢您对兜兜龙的支持，如果您有任何意见或建议，<br />请填写以下表格反馈给我们。<br />我们会即时采纳，力求为您提供满意的服务。
            </div>

            <div class="form">
                <div class="form-question">
                    <div class="form-title">问题反馈：</div>
                    <div class="form-textarea">
                        <textarea
                            placeholder="请输入100字以内的反馈"
                            rows="4"
                            v-model="form.question"
                            @input="onQuestionInput"
                        />
                        <div class="form-textarea__num">
                            {{ formQuestionLen }}/100
                        </div>
                    </div>
                </div>

                <div class="form-user-info">
                    <div class="form-title">请填写您的个人信息：</div>

                    <div class="form-item">
                        <div class="form-label">兜兜号：</div>
                        <input
                            @input="onNumberInput($event, 'ddlid')"
                            v-model="form.ddlid"
                        />
                    </div>

                    <div class="form-item">
                        <div class="form-label">
                            联系电话：
                        </div>
                        <input @input="onPhoneInput" v-model="form.phone" />
                    </div>

                    <div class="form-item">
                        <div class="form-label">
                            联系QQ：
                        </div>
                        <input
                            @input="onNumberInput($event, 'qq')"
                            v-model="form.qq"
                        />
                    </div>
                </div>

                <div @click="onClick" class="form-submit">
                    <img
                        src="@/assets/images/container/questions/btn_yes.png"
                    />
                </div></div
        ></b-container>

        <!-- <b-button id="show-btn" @click="">Open Modal</b-button> -->

        <b-modal id="modal" hide-footer centered>
            <template v-slot:modal-title>
                提示
            </template>
            <div class="d-block text-center">
                {{ tips }}
            </div>
            <b-button
                class="mt-3 modal-btn"
                block
                @click="$bvModal.hide('modal')"
                >我知道了</b-button
            >
        </b-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                question: ""
            },
            tips: ""
        };
    },

    methods: {
        isPhoneNum(phone) {
            let mobileTest = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
            let phoneTest = /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/;

            return mobileTest.test(phone) || phoneTest.test(phone);
        },

        check() {
            if (!this.form.question) {
                return "问题或建议";
            }

            if (!this.form.ddlid) {
                return "兜兜号";
            }

            if (!this.form.phone && !this.form.qq) {
                return "联系方式";
            }

            return false;
        },

        onClick() {
            let msg = this.check();
            if (msg) {
                this.tips = `请输入您的${msg}`;
                this.$bvModal.show("modal");
                return;
            }

            if (this.form.phone && !this.isPhoneNum(this.form.phone)) {
                this.tips = `请输入正确的联系电话`;
                this.$bvModal.show("modal");
                return;
            }

            if (this.form.ddlid > 99999999) {
                this.tips = `请输入正确的兜兜号`;
                this.$bvModal.show("modal");
                return;
            }

            console.log(this.form);

            this.$api
                .request({
                    url: "xingwawa/index.php",
                    method: "post",
                    params: {
                        m: "gm",
                        a: "set_contact_info",
                        ...this.form
                    }
                })
                .then(() => {
                    this.tips = "您的问题和建议已收到，我们会认真处理您的反馈";
                    this.$bvModal.show("modal");
                });
        },

        onPhoneInput(e) {
            this.$set(
                this.form,
                "phone",
                e.target.value.replace(/[^0-9-]/g, "")
            );

            console.log(e.target.value);
        },

        onNumberInput(e, type) {
            this.$set(this.form, type, e.target.value.replace(/[^0-9]/g, ""));
        },

        onQuestionInput(e) {
            if (this.formQuestionLen > 100) {
                this.$set(this.form, "question", e.target.value.substr(0, 100));
            }
        }
    },

    computed: {
        formQuestionLen() {
            return this.form.question.length;
        }
    }
};
</script>

<style lang="less" scoped>
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
    .contact {
        text-align: left;

        &-msg {
            color: #3e3a39;
            font-size: calc(100vw / 1920 * 24);
            margin-bottom: calc(100vw / 1920 * 40);

            &--mobile {
                display: none;
            }
        }
    }

    .form {
        color: #3e3a39;
        font-size: calc(100vw / 1920 * 24);

        &-title {
            margin-bottom: calc(100vw / 1920 * 20);
            font-weight: bold;
        }

        &-submit {
            cursor: pointer;
            text-align: center;
            margin: calc(100vw / 1920 * 80) auto 0;
            width: calc(100vw / 1920 * 240);

            img {
                width: 100%;
            }
        }

        &-item {
            margin-bottom: calc(100vw / 1920 * 20);
            display: flex;
            align-items: center;

            input {
                border: none;
                height: calc(100vw / 1920 * 48);
                width: calc(100vw / 1920 * 400);
                border-radius: calc(100vw / 1920 * 10);
                color: #3e3a39;
                padding-left: calc(100vw / 1920 * 24);
            }
        }

        &-label {
            min-width: calc(100vw / 1920 * 180 * 0.8);
        }

        &-question {
            textarea {
                padding: calc(100vw / 1920 * 24);
                width: 100%;
                border: none;
                outline: none;
                border-radius: calc(100vw / 1920 * 20);

                &::placeholder {
                    color: #c4c4c4;
                }
            }
        }

        &-textarea {
            position: relative;
            margin-bottom: calc(100vw / 1920 * 40);

            &__num {
                position: absolute;
                right: calc(100vw / 1920 * 20);
                bottom: calc(100vw / 1920 * 20);
                color: #c4c4c4;
                font-size: calc(100vw / 1920 * 24);
                background: #fff;
            }
        }
    }

    .modal {
        &-btn {
            background: #58cde4;
            border: none;
        }
    }
};

@media (max-width: 1024px) {
    .contact {
        text-align: left;

        &-msg {
            display: none;

            &--mobile {
                display: block;
                color: #6f6f6f;
                font-size: calc(100vw / 1080 * 36);
                margin-bottom: calc(100vw / 1080 * 50);
                text-align: center;
                line-height: 1.667;
            }
        }
    }

    .form {
        color: #3e3a39;
        font-size: calc(100vw / 1080 * 42);

        &-title {
            margin-bottom: calc(100vw / 1080 * 30);
            font-weight: bold;
        }

        &-submit {
            cursor: pointer;
            text-align: center;
            margin-top: calc(100vw / 1080 * 80);
            width: calc(100vw / 1080 * 450);

            img {
                width: calc(100vw / 1080 * 450);
            }
        }

        &-item {
            margin-bottom: calc(100vw / 1080 * 20);
            display: flex;

            input {
                border: none;
                height: calc(100vw / 1080 * 100);
                width: calc(100vw / 1080 * 600);
                border-radius: calc(100vw / 1080 * 20);
                color: #3e3a39;
            }
        }

        &-label {
            min-width: calc(100vw / 1080 * 240);
            font-size: calc(100vw / 1080 * 48);
        }

        &-question {
            textarea {
                padding: calc(100vw / 1080 * 36);
                padding-bottom: calc(100vw / 1080 * 72);
                width: 100%;
                border: none;
                outline: none;
                border-radius: calc(100vw / 1080 * 30);

                &::placeholder {
                    color: #c4c4c4;
                }
            }
        }

        &-textarea {
            position: relative;
            margin-bottom: calc(100vw / 1080 * 50);

            &__num {
                position: absolute;
                right: calc(100vw / 1080 * 30);
                bottom: calc(100vw / 1080 * 35);
                color: #c4c4c4;
                font-size: calc(100vw / 1080 * 36);
            }
        }
    }
}
</style>
