var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"questions-tabs"},[_c('b-container',[_c('div',{staticClass:"search",on:{"click":_vm.onClick}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPlaceholderOpened),expression:"isPlaceholderOpened"}],staticClass:"search-placeholder"},[_c('img',{attrs:{"src":require("@/assets/images/container/questions/search.png")}}),_vm._v(" 输入关键字搜索问题与答案 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"input",staticClass:"search-input",domProps:{"value":(_vm.search)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_vm._v(" "+_vm._s(_vm.search)+" ")]),_c('div',{staticClass:"view"},[_c('div',{ref:"viewLeft",staticClass:"view-left"},_vm._l((_vm.faqs),function(list,key){return _c('div',{key:key,class:[
                        'view-tab',
                        _vm.current === key && 'view-tab--current'
                    ],on:{"click":function($event){return _vm.onTabClick(key)}}},[_vm._v(" "+_vm._s(key)+" ")])}),0),_c('div',{ref:"viewRight",class:[
                    'view-right',
                    _vm.isViewRightRadius && 'view-right--radius'
                ]},_vm._l((_vm.questions),function(item,idx){return _c('div',{key:idx,staticClass:"view-item",on:{"click":function($event){return _vm.onQuestionClick(item.id, idx)}}},[_c('div',{staticClass:"view-item__title"},[_c('img',{staticClass:"question",attrs:{"src":require("@/assets/images/container/questions/question.png")}}),_c('span',[_vm._v(_vm._s(item.title))]),_c('img',{class:[
                                'arrow',
                                item.isOpened && 'arrow--open'
                            ],attrs:{"src":require("@/assets/images/container/questions/arrow.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(item.isOpened),expression:"item.isOpened"}],staticClass:"detail"},[_c('div',{staticClass:"detail-answer"},[_vm._v("答：")]),_c('div',{staticClass:"detail-list"},_vm._l((_vm.details[item.id]),function(detail,idx){return _c('div',{key:idx,staticClass:"detail-item"},[_c('div',{staticClass:"detail-txt"},[_vm._v(" "+_vm._s(detail.text)+" ")]),_c('div',{staticClass:"detail-img"},[(detail.img)?_c('img',{attrs:{"src":detail.img}}):_vm._e()])])}),0)])])}),0)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }