<template>
	<div>
		<!-- 常见问题 -->
		<div class="container-item">
			<picture>
				<source
					media="(max-width: 1024px)"
					srcset="@/assets/images/mobile/container/questions/title_1.png"
				/>
				<source
					media="(min-width: 1025px)"
					srcset="@/assets/images/container/questions/title_1.png"
				/>
				<img
					class="container-item__title"
					src="@/assets/images/container/questions/title_1.png"
				/>
			</picture>

			<template v-if="isMobile">
				<MobileQuestionsTabs />
			</template>
			<template v-else>
				<QuestionsTabs />
			</template>
		</div>

		<!-- 联系客服 -->
		<div class="container-item">
			<picture>
				<source
					media="(max-width: 1024px)"
					srcset="@/assets/images/mobile/container/questions/title_2.png"
				/>
				<source
					media="(min-width: 1025px)"
					srcset="@/assets/images/container/questions/title_2.png"
				/>
				<img
					class="container-item__title"
					src="@/assets/images/container/questions/title_2.png"
				/>
			</picture>

			<Contact />
		</div>
		<div class="qq_txt">微信联系客服</div>
		<a
			target="_blank"
			href="https://work.weixin.qq.com/kfid/kfcda4f0889a37699f1"
		><img
				class="qq"
				src="@/assets/images/container/questions/qrcode.png"
			/></a>
	</div>
</template>

<script>
import QuestionsTabs from "./questions-tabs";
import MobileQuestionsTabs from "./mobile-questions-tabs";
import Contact from "./contact";

export default {
	components: {
		QuestionsTabs,
		MobileQuestionsTabs,
		Contact
	},

	data() {
		return {
			isMobile: this.$userAgent.isMobile
		};
	},

	mounted() {}
};
</script>

<style lang="less">
@import "../styles/mixins.less";

.get-container-max-width(@styles);

@styles: {
	.container {
		&-item {
			margin-bottom: calc(100vw / 1920 * 150);
            text-align: center;
            
            .container{
                padding: 15px;
                width: calc(100vw / 1920 * 960);
            }

			&__title {
				margin-bottom: calc(100vw / 1920 * 20);
				width: calc(100vw / 1920 * 272);
			}

			&__header {
				width: 100%;
				vertical-align: bottom;
			}

			&__footer {
				width: 100%;
				vertical-align: top;
			}
		}
	}

	.qq {
		position: fixed;
		right: calc(100vw / 1920 * 80);
		bottom: calc(100vw / 1920 * 60);
		cursor: pointer;
		z-index: 9999;
		width: calc(100vw / 1920 * 200);
	}
	.qq_txt{
		position: fixed;
		right: calc(100vw / 1920 * 80);
		bottom: calc(100vw / 1920 * 260);
		cursor: pointer;
		z-index: 9999;
		width: calc(100vw / 1920 * 200);
		font-weight: bold;
		text-align: center;
		background-color: white;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
};

@media (max-width: 1024px) {
	.container {
		&-item {
			margin-bottom: calc(100vw / 1080 * 100);
            text-align: center;

            .container{
                width: auto;
            }

			&__title {
				width: calc(100vw / 1080 * 510);
				margin-bottom: calc(100vw / 1080 * 30);
			}

			&__header {
				width: 100%;
				vertical-align: bottom;
			}

			&__footer {
				width: 100%;
				vertical-align: top;
			}
		}
	}

	.qq {
		position: relative;
		left: 50%;
		bottom: auto;
		cursor: pointer;
		width: calc(100vw / 1080 * 300);
		margin-bottom: calc(100vw / 1080 * 70);
		transform: translateX(-50%);
		z-index: 0;
	}

	.qq_txt{
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		bottom: auto;
		cursor: pointer;
		z-index: 0;
		width: calc(100vw / 1920 * 450);
		// margin-bottom: calc(100vw / 1080 * 70);
		font-weight: bold;
		text-align: center;
		background-color: white;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}
}
</style>
