<template>
    <div class="questions-tabs">
        <b-container>
            <div @click="onClick" class="search">
                <div v-show="isPlaceholderOpened" class="search-placeholder">
                    <img
                        src="@/assets/images/mobile/container/questions/search.png"
                    />
                    输入关键字搜索问题与答案
                </div>

                <input
                    ref="input"
                    @blur="onBlur"
                    class="search-input"
                    v-model="search"
                />
            </div>

            <div class="view">
                <div class="view-tabs">
                    <div
                        :class="[
                            'view-tab',
                            current === key && 'view-tab--current'
                        ]"
                        v-for="(list, key) in faqs"
                        :key="key"
                        @click="onTabClick(key)"
                    >
                        {{ key }}
                    </div>
                </div>
                <div class="view-questions">
                    <div class="view-questions__msg">点击问题快速查看</div>
                    <div
                        class="view-item"
                        v-for="(item, idx) in questions"
                        :key="idx"
                        @click="onQuestionClick(item.id, idx)"
                    >
                        <div class="view-item__title">
                            <img
                                class="question"
                                src="@/assets/images/mobile/container/questions/question.png"
                            />
                            <span class="view-item__title-txt">{{
                                item.title
                            }}</span>
                            <!-- <img
                                :class="[
                                    'arrow',
                                    item.isOpened && 'arrow--open'
                                ]"
                                src="@/assets/images/mobile/container/questions/arrow.png"
                            /> -->
                        </div>

                        <!-- <MobileQuestionDetail v-show="item.isOpened" /> -->

                        <!-- <div v-show="item.isOpened" class="detail">
                            <div class="detail-answer">答：</div>
                            <div class="detail-list">
                                <div
                                    v-for="(detail, idx) in details[item.id]"
                                    :key="idx"
                                    class="detail-item"
                                >
                                    <div class="detail-txt">
                                        {{ detail.text }}
                                    </div>
                                    <div class="detail-img">
                                        <img
                                            v-if="detail.img"
                                            :src="detail.img"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            search: "",
            isPlaceholderOpened: true,
            faqs: {},
            allFaqs: [],
            current: "常见问题",
            details: {},
            questions: []
        };
    },

    methods: {
        onClick() {
            this.isPlaceholderOpened = false;
            this.$refs.input.focus();
        },

        onBlur() {
            this.isPlaceholderOpened = !this.search;
        },

        onTabClick(key) {
            this.current = key;
            this.questions = this.faqs[key];
        },

        getFaqs() {
            this.$api
                .request({
                    url: "xingwawa/index.php",
                    method: "get",
                    params: {
                        m: "gm",
                        a: "get_faq_list_web"
                    }
                })
                .then(res => {
                    let faqs = {};
                    let allFaqs = [];
                    res.ret.forEach(item => {
                        let { id, type, title } = item;

                        type = decodeURIComponent(type);
                        title = decodeURIComponent(title);

                        const _item = {
                            id,
                            type,
                            title,
                            isOpened: 0
                        };

                        faqs[type]
                            ? faqs[type].push(_item)
                            : (faqs[type] = [_item]);

                        allFaqs.push(_item);
                    });

                    this.faqs = faqs;
                    this.questions = this.faqs[this.current];
                    this.allFaqs = allFaqs;

                    console.log(faqs);
                });
        },

        onQuestionClick(id) {
            this.$router.push({
                path: "/mobile-question-detail",
                query: {
                    id
                }
            });
        }
    },

    watch: {
        search() {
            let questions = this.allFaqs.filter(item => {
                return item.title.indexOf(this.search) > -1;
            });

            this.questions = questions;
        }
    },

    mounted() {
        this.getFaqs();
    }
};
</script>

<style lang="less" scoped>
.questions-tabs {
    position: relative;
}

.view {
    &-tabs {
        display: flex;
        align-items: center;
        margin-bottom: calc(100vw / 1080 * 30);
    }

    &-tab {
        width: calc(100vw / 1080 * 240);
        height: calc(100vw / 1080 * 90);
        line-height: calc(100vw / 1080 * 90);
        border-radius: calc(100vw / 1080 * 45);
        color: #fff;
        font-size: calc(100vw / 1080 * 36);
        background: #58cde4;

        &--current {
            background: #58aae4;
        }

        + .view-tab {
            margin-left: calc(100vw / 1080 * 20);
        }
    }

    &-questions {
        width: 100%;
        padding-top: calc(100vw / 1080 * 30);
        padding-bottom: calc(100vw / 1080 * 90);
        border: calc(100vw / 1080 * 2) solid #eeeeee;
        border-radius: calc(100vw / 1080 * 30);
        background: #fff;

        &__msg {
            color: #c4c4c4;
            font-size: calc(100vw / 1080 * 36);
            text-align: left;
            padding-left: calc(100vw / 1080 * 40);
            margin-bottom: calc(100vw / 1080 * 60);
        }
    }

    &-item {
        font-size: calc(100vw / 1080 * 46);
        color: #495e8f;
        text-align: left;
        margin-left: calc(100vw / 1080 * 40);

        + .view-item {
            .view-item__title-txt {
                border-top: calc(100vw / 1080 * 2) solid rgb(238, 238, 238);
            }
        }

        .question {
            width: calc(100vw / 1080 * 54);
            margin-right: calc(100vw / 1080 * 20);
        }

        &__title {
            display: flex;
            align-items: center;

            &-txt {
                display: inline-block;
                min-width: calc(100vw / 1080 * 600);
                padding: calc(100vw / 1080 * 40) 0;
            }
        }
    }
}

.search {
    color: #c4c4c4;
    font-size: calc(100vw / 1080 * 36);
    height: calc(100vw / 1080 * 120);
    background: #fff;
    margin-bottom: calc(100vw / 1080 * 30);
    text-align: center;
    border-radius: calc(100vw / 1080 * 20);
    overflow: hidden;
    position: relative;

    &-placeholder {
        position: absolute;
        left: 50%;
        top: 0;
        width: calc(100vw - 30px);
        height: calc(100vw / 1080 * 120);
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);

        img {
            width: calc(100vw / 1080 * 57);
            margin-right: calc(100vw / 1080 * 45);
        }
    }

    input {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
    }
}
</style>
